import React from 'react'
import { Box , Typography} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ErrorIcon from '@mui/icons-material/Error';
import {  GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useNavigate } from 'react-router-dom';
// Custom toolbar including export functionality
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
// Custom cell renderer for the 'Tabular Data' column
function TabularDataCell(params) {
    const navigate = useNavigate();
  const handleClick = () => {
    // window.open(`${window.location.pathname}/SS${params.row.DeviceID}`, '_blank');
    navigate(`/Admin/SSLDATA/${params.row.DeviceID}`);
  };

  return (
    <IconButton onClick={handleClick} sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
      color: 'white', 
        padding: '10px', 
        '&:hover': {
          background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
        },
        borderRadius: '10px',
    }} >
      <TableChartIcon />
    </IconButton>
  );
}

const statuses = ['Charging', 'Normal Operation', 'Bulb Not Sending Data','Fault'];

// const getStatusIcon = (status) => {
//   switch (status) {
//     case 'Charging':
//       return <BatteryChargingFullIcon />;
//     case 'Normal Operation':
//       return <BatteryFullIcon />;
//     case 'Bulb Not Sending Data':
//       return <PowerOffIcon />;
//     case 'Fault' : 
//       return <ErrorIcon />;
//     default:
//       return null;
//   }
// };

// const SystemStatusCell = ({ value }) => (
//   <Box display="flex" alignItems="center">
//     {getStatusIcon(value)}
//     <Typography variant="body2" ml={1}>{value}</Typography>
//   </Box>
// );

const getStatusIcon = (status, color) => {
  switch (status) {
    case 'Charging':
      return <BatteryChargingFullIcon style={{ color }} />;
    case 'Normal Operation':
      return <BatteryFullIcon style={{ color }} />;
    case 'Bulb Not Sending Data':
      return <PowerOffIcon style={{ color }} />;
    case 'Fault':
      return <ErrorIcon style={{ color }} />;
    default:
      return null;
  }
};

const SystemStatusCell = ({ value }) => {
  let iconColor;
  switch (value) {
    case 'Charging':
      iconColor = 'green';
      break;
    case 'Normal Operation':
      iconColor = 'blue';
      break;
    case 'Bulb Not Sending Data':
      iconColor = 'orange';
      break;
    case 'Fault':
      iconColor = 'red';
      break;
    default:
      iconColor = 'black';
      break;
  }

  return (
    <Box display="flex" alignItems="center">
      {getStatusIcon(value, iconColor)}
      <Typography variant="body2" ml={1}>{value}</Typography>
    </Box>
  );
};
const Unpluggedlight = React.memo(function Unpluggedlight() {
  const columns = [
    { field: 'DeviceID', headerName: 'Device ID-IMEI', width: 150 },
    { field: 'RMSMobileNo', headerName: 'RMS Mobile No', width: 150 },
    // { field: 'Config', headerName: 'Config', width: 150 },
    { field: 'PoleSerialNo', headerName: 'Pole Serial No', width: 150 },
    { field: 'LuminiareSrNo', headerName: 'Luminiare Sr. No.', width: 150 },
    { field: 'PanelSrNo', headerName: 'Panel Sr. No.', width: 150 },
    { field: 'BatterySrNo', headerName: 'Battery Sr. No.', width: 150 },
    { 
      field: 'SystemStatus', 
      headerName: 'System Status', 
      width: 200,
      renderCell: (params) => <SystemStatusCell value={params.value} />,
      sortable: false,
      filterable: false
    },
    { field: 'LastUpdated', headerName: 'Last Updated', width: 150 },
    { 
      field: 'tabularData', 
      headerName: 'Tabular Data', 
      width: 150, 
      renderCell: TabularDataCell,
      sortable: false,
      filterable: false
    },
  ];

  const rows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    DeviceID: `86827704855${index}03`,
    RMSMobileNo: `57550200664${index}7`,
    // Config: `QCT_F157${index}`,
    PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
    LuminiareSrNo: `08234855910${index}`,
    PanelSrNo: `IBE12012084${index}6`,
    BatterySrNo: `INUBUC1254002${index}`,
    SystemStatus: statuses[index % statuses.length],
    LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
  }));
  const unpluggedRows = rows.filter(row => row.SystemStatus === 'Bulb Not Sending Data');
  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h3'>Solar Street Light Data</Typography>
          <Typography variant='h6'>Unplugged - Solar Street Light Data</Typography>
        </Box>
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid 
            rows={unpluggedRows}
            columns={columns}
            pageSize={10}
            components={{ Toolbar: CustomToolbar }}
            rowsPerPageOptions={[10]}
            sx={{
              '& .MuiDataGrid-columnHeaders': {     
                top: 0,          
              },
            }}
          />
        </div>
      </Box>
    </>
  );
});
export default Unpluggedlight

// I have all the solar light data // i have also the tabular data how to store it ?
// From where the scheduling handled 
