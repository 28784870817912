import React from 'react'
import "./index.css";
import "./spinner.css"

function Spinner() {
  return (
    <div className='spinner-container' >
      <div className='spinner' >

      </div>
    </div>
  )
}

export default Spinner
