// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import Snackbar from '@mui/material/Snackbar';
// import axios from 'axios';



// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://solalrstreetlightyojana.com/">
//         solalrstreetlightyojana.com 
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }


// const defaultTheme = createTheme();

// export default function SignIn({setAuth}) {
  
//   const [open, setOpen] = React.useState(false);
//   const [snackbarMessage, setSnackbarMessage] = React.useState('');
 
//     const navigate = useNavigate();
//   const handleSubmit = (event) => {

//     // console.log(event)
//     event.preventDefault();
//     // const data = new FormData(event.currentTarget);

//     const data = new FormData(event.currentTarget);
//     const email = data.get('email');
//     const password = data.get('password');
//       // console.log(email);
//       // console.log(password)
//     if( email && password &&  email !== "" && password !== ""){
//       axios.post("https://www.ielecssol.solarlighting.co.in/api/v1/login", 
//         {
//         email: data.get('email'),
//         password: data.get('password')})
//         .then(
//           (response)=>{
//             console.log(response.data);
//             // if( response.data){
//               console.log(`comes to authenticate `)
//               setAuth(true);
//               localStorage.setItem("auth", "true");
//               navigate("/Admin");
//             // }
//           }
//         ).catch(
//           (err)=>{
//             setOpen(true);
//             setSnackbarMessage(" Login Failed Incorrect Crendential");
//             console.log(err);
//           }
//         );
//     }else if( email && !password ){
//       setOpen(true);
//       setSnackbarMessage("Please enter  password.");
//       console.log("Please enter your password.");
//     }else if(password && !email){
//       setOpen(true);
//       setSnackbarMessage("Please enter email ");
//       console.log("Please enter your email.");
//     }else{
//       setOpen(true);
//       setSnackbarMessage("Please enter both email and password.");
//       console.log("Please enter both email and password.");

//     }

//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
   
//   };

  

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             // background : 'black'
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Log in
//           </Typography>
//           <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//             />
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
             
//             >
//               Log In
//             </Button>
//             <Grid container>
//               <Grid item xs>
//                 <Link href="/" variant="body2">
//                   Forgot password?
//                 </Link>
//               </Grid>
//               {/* <Grid item>
//                 <Link href="#" variant="body2">
//                   {"Don't have an account? Sign Up"}
//                 </Link>
//               </Grid> */}
//             </Grid>
//           </Box>
//         </Box>
//         <Copyright sx={{ mt: 8, mb: 4 }} />
//         <Snackbar
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//         open={open}
//         onClose={handleClose}
//         autoHideDuration={2000}
//         message={snackbarMessage}
//         key="topcenter"
//       />
//       </Container>
//     </ThemeProvider>
//   );
// }

// // import React from 'react';
// // // import { IoMdSunny } from 'react-icons/io';
// // import loginimage from './loginimage.jpg';
// // // import logo from '../components/logo.png';
// // import {
// //   Box,
// //   Button,
// //   Checkbox,
// //   Container,
// //   FormControlLabel,
// //   Grid,
// //   Link,
// //   TextField,
// //   Typography,
// //   Paper,
// //   Divider,
// // } from '@mui/material';
// // import { styled } from '@mui/system';
// // // import './login.css';

// // const SignIn = ({ setAuth }) => {
// //   const loginHandler = (event) => {
// //     event.preventDefault();
// //     setAuth(true);
// //   };

// //   const LoginImage = styled('img')({
// //     width: '100%',
// //     objectFit: 'cover',
// //   });

// //   // const AuthLogo = styled('img')({
// //   //   height: '9rem',
// //   //   width: 'auto',
// //   // });

// //   return (
// //     <Container maxWidth="xs">
// //       <Box
// //         display="flex"
// //         flexDirection="column"
// //         justifyContent="center"
// //         alignItems="center"
// //         minHeight="100vh"
// //         bgcolor="black"
// //         color="white"
// //       >
// //         <Link href="index">
// //           {/* <AuthLogo src={logo} alt="Auth Logo" /> */}
// //         </Link>

// //         <Paper
// //           component="form"
// //           onSubmit={loginHandler}
// //           elevation={6}
// //           sx={{ padding: 4, mt: 2, bgcolor: 'black', color: 'white' }}
// //         >
// //           <Grid container spacing={2}>
// //             <Grid item xs={12} display="flex" justifyContent="center">
// //               <LoginImage src={loginimage} alt="Sample" />
// //             </Grid>
// //             <Grid item xs={12}>
// //               <Typography variant="h6" textAlign="center">
// //                 Welcome Back!
// //               </Typography>
// //               <Typography variant="body2" textAlign="center" color="gray">
// //                 To MukhyaMantri Solar Street Light Yojana.
// //               </Typography>
// //               <Divider sx={{ my: 2 }}>
// //                 {/* <IoMdSunny size={20} color="yellow" /> */}
// //               </Divider>
// //             </Grid>
// //             <Grid item xs={12}>
// //               <TextField
// //                 fullWidth
// //                 required
// //                 label="Email Address"
// //                 variant="outlined"
// //                 size="small"
// //                 InputProps={{ style: { color: 'white' } }}
// //                 InputLabelProps={{ style: { color: 'gray' } }}
// //                 sx={{ bgcolor: 'black' }}
// //               />
// //             </Grid>
// //             <Grid item xs={12}>
// //               <TextField
// //                 fullWidth
// //                 required
// //                 label="Password"
// //                 type="password"
// //                 variant="outlined"
// //                 size="small"
// //                 InputProps={{ style: { color: 'white' } }}
// //                 InputLabelProps={{ style: { color: 'gray' } }}
// //                 sx={{ bgcolor: 'black' }}
// //               />
// //             </Grid>
// //             <Grid item xs={12} display="flex" justifyContent="space-between">
// //               <FormControlLabel
// //                 control={<Checkbox color="primary" />}
// //                 label="Remember Me"
// //                 sx={{ color: 'white' }}
// //               />
// //               <Link href="/" color="inherit">
// //                 Forgot Password?
// //               </Link>
// //             </Grid>
// //             <Grid item xs={12}>
// //               <Button
// //                 fullWidth
// //                 variant="contained"
// //                 color="primary"
// //                 type="submit"
// //                 sx={{ bgcolor: 'amber.300', color: 'black', mt: 2 }}
// //               >
// //                 Login
// //               </Button>
// //             </Grid>
// //           </Grid>
// //         </Paper>
// //         <Box mt={4} textAlign="center">
// //           <Typography variant="body2" color="gray">
// //             © 2024 MukhyaMantri Solar Street Light Yojana.
// //           </Typography>
// //         </Box>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default SignIn;

// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import Snackbar from '@mui/material/Snackbar';
// import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
// import axios from 'axios';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://solalrstreetlightyojana.com/">
//         solalrstreetlightyojana.com 
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const defaultTheme = createTheme();

// export default function SignIn({ setAuth }) {
//   const [open, setOpen] = React.useState(false);
//   const [snackbarMessage, setSnackbarMessage] = React.useState('');
//   const [loading, setLoading] = React.useState(false); // State for loading spinner

//   const navigate = useNavigate();

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     const data = new FormData(event.currentTarget);
//     const email = data.get('email');
//     const password = data.get('password');

//     if (email && password && email !== "" && password !== "") {
//       setLoading(true); // Start loading spinner

//       axios.post("https://www.ielecssol.solarlighting.co.in/api/v1/login", 
//         {
//           email: data.get('email'),
//           password: data.get('password')
//         })
//         .then((response) => {
//           console.log(response.data);
//           setAuth(true);
//           localStorage.setItem("auth", "true");
//           navigate("/Admin");
//         })
//         .catch((err) => {
//           setOpen(true);
//           setSnackbarMessage("Login Failed: Incorrect Credentials");
//           console.log(err);
//         })
//         .finally(() => {
//           setLoading(false); // Stop loading spinner
//         });
//     } else if (email && !password) {
//       setOpen(true);
//       setSnackbarMessage("Please enter your password.");
//       console.log("Please enter your password.");
//     } else if (password && !email) {
//       setOpen(true);
//       setSnackbarMessage("Please enter your email.");
//       console.log("Please enter your email.");
//     } else {
//       setOpen(true);
//       setSnackbarMessage("Please enter both email and password.");
//       console.log("Please enter both email and password.");
//     }
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Log in
//           </Typography>
//           <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//             />
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//               disabled={loading} // Disable button while loading
//             >
//               {loading ? <CircularProgress size={24} /> : 'Log In'} {/* Spinner or Log In text */}
//             </Button>
//             <Grid container>
//               <Grid item xs>
//                 <Link href="/" variant="body2">
//                   Forgot password?
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//         <Copyright sx={{ mt: 8, mb: 4 }} />
//         <Snackbar
//           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//           open={open}
//           onClose={handleClose}
//           autoHideDuration={2000}
//           message={snackbarMessage}
//           key="topcenter"
//         />
//       </Container>
//     </ThemeProvider>
//   );
// }
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
// import axios from 'axios';
import {  IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Spinner from './Spinner';
import { useState } from 'react';
import { login } from './service/Operations/authapi';
import { useDispatch } from 'react-redux';
import "./index.css";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://solalrstreetlightyojana.com/">
        solalrstreetlightyojana.com 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const defaultTheme = createTheme();
export default function SignIn({ setAuth }) { 


  const dispatch =  useDispatch();

  const [loading,setLoading] =  useState(false)
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    if (email && password && email !== "" && password !== "") {
      // axios.post("https://www.ielecssol.solarlighting.co.in/api/v1/login", 
      //   { email: data.get('email'), password: data.get('password') } , 
      //   {
      //     withCredentials: true // Important to allow the browser to store the HTTP-only cookie  when you have to push to the development phase check it 
      // }
      // )
      //   .then((response) => {
      //     if( response.status === 200 ){
      //       setAuth(true);
      //       localStorage.setItem("auth", "true");
      //       setLoading(false);
      //       navigate("/Admin", { state: { loginSuccess: true, username: email } });
            
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setOpen(true);
      //     setSnackbarMessage("Login Failed: Incorrect Credential");
      //   });

        // dispatchEvent()
        dispatch(login( email ,  password , navigate , setAuth , setLoading , setOpen , setSnackbarMessage  ));
       

    } else if (email && !password) {
      setLoading(false);
      setOpen(true);
      setSnackbarMessage("Please enter password.");
    } else if (password && !email) {
      setLoading(false);
      setOpen(true);
      setSnackbarMessage("Please enter email.");
    } else {
      setLoading(false);
      setOpen(true);
      setSnackbarMessage("Please enter both email and password.");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container   component="main" maxWidth="xs">
        <CssBaseline />
        { loading ?  <Spinner /> : <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />   */}
               <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type={showPassword ? 'text' : 'password'}
      id="password"
      autoComplete="current-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/signin" variant="body2">
                  Create account
                </Link>
              </Grid>

              <Grid item xs>
                <Link href="/" variant="body2">
                   Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>  }
        
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          autoHideDuration={2000}
          message={snackbarMessage}
          key="topcenter"
        />
      </Container>
    </ThemeProvider>
  );
}
