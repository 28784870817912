import axios from "axios"
import { setToken } from "../../slices/authSlice"

 export const signUp =   (
    // accountType,
    firstName,
    lastName,
    email,
    password,
    // confirmPassword,
    otp,
    navigate
  ) =>  {
    return async (dispatch) => {
      // const toastId = toast.loading("Loading...")
      // dispatch(setLoading(true))
      try {
        const response = await axios.post('https://www.ielecssol.solarlighting.co.in/api/v1/signup' , {
          firstName , lastName , password , email , otp
        }) 
        //  apiConnector("POST", SIGNUP_API, {
        //   // accountType,
        //   firstName,
        //   lastName,
        //   email,
        //   password,
        //   // confirmPassword,
        //   otp,
        // })
        console.log("SIGNUP API RESPONSE............", response)
        if (!response.data.success) {
          throw new Error(response.data.message)
        }
        // toast.success("Signup Successful")
        navigate("/")
      } catch (error) {
        console.log("SIGNUP API ERROR............", error)
        // toast.error("Signup Failed")
        
        navigate("/signup")
      }
      // dispatch(setLoading(false))
      // toast.dismiss(toastId)
    }
  }


  export const  login =  (email, password, navigate , setAuth ,  setLoading   , setOpen , setSnackbarMessage ) => {
    return async (dispatch) => {
      // const toastId = toast.loading("Loading...")
      // dispatch(setLoading(true))
      try {
        // const response = await , LOGIN_API, {
        //   email,
        //   password,
        // })
        const response = await axios.post('https://www.ielecssol.solarlighting.co.in/api/v1/login' , {
          password , email 
        },{
          withCredentials : true
        })
        console.log("LOGIN API RESPONSE............", response)
        if (!response.data.success) {
          throw new Error(response.data.message)
        }
        console.log(" Checking her ")
        // toast.success("Login Successful")
        dispatch(setToken(response.data.token))
        const userImage = response.data?.user?.image
          ? response.data.user.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
          // ${response.data.user.lastName}
        // dispatch(setUser({ ...response.data.user, image: userImage }))
        localStorage.setItem("profile-image",JSON.stringify(userImage));
        localStorage.setItem("token", JSON.stringify(response.data.token))
        localStorage.setItem("user", JSON.stringify(response.data.user))
        // navigate("/Admin")
        setAuth(true);
        localStorage.setItem("auth", "true");
        setLoading(false);
        navigate("/Admin", { state: { loginSuccess: true, username: email } });
      } catch (error) {
        console.log("LOGIN API ERROR............", error)
          setLoading(false);
          setOpen(true);
          setSnackbarMessage("Login Failed: Incorrect Credential");
        // toast.error("Login Failed")
      }
      // dispatch(setLoading(false))
      // toast.dismiss(toastId)
    }
  }

  export default signUp