// import   { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, Badge, Avatar, Button } from '@mui/material';
import { styled } from '@mui/system';
import logo from './bg-banner.jpg';
import bgimage from './default-profile-picture.png';
import { DataGrid } from '@mui/x-data-grid';
import {   useLocation  } from 'react-router-dom';
import { tokens } from '../../theme';
import {useTheme} from '@mui/material';
import StatBox from '../../components/StatBox'

import { ssldata } from '../../data/ssldata';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ErrorIcon from '@mui/icons-material/Error';
import BasicArea from '../../components/LineChart';
import Header from '../../components/Header';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';


const OverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));
 const BannerImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  [theme.breakpoints.up('sm')]: {
    height: '300px',
  },
  [theme.breakpoints.up('md')]: {
    height: '400px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '300px',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const ResponsiveBox2 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '& > *': {
    margin: theme.spacing(1),
  },
}));

// Main component
function Tabularview() {
  // const { id } = useParams();
  // const { deviceID } = useParams();
  const [showParameters, setShowParameters] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [faults, setShowFaults] = useState(false);
  const [graphs, setGraphs] = useState(false);

  // console.log("This is the device  id",deviceID);
  console.log("This is the ssl data ",ssldata);


// const theme = useTheme();

  const handleButtonClick = (buttonType) => {
    if (buttonType === 'parameters') {
      setShowAlert(false);
      setShowFaults(false);
      setGraphs(false);
      setShowParameters(true);
    } else if (buttonType === 'showAlert') {
      setShowAlert(true);
      setShowFaults(false);
      setGraphs(false);
      setShowParameters(false);
    } else if (buttonType === 'showFault') {
      setShowAlert(false);
      setShowFaults(true);
      setGraphs(false);
      setShowParameters(false);
    } else if (buttonType === 'graphs') {
      setShowAlert(false);
      setShowFaults(false);
      setGraphs(true);
      setShowParameters(false);
    }
  };

  const exportData = () => {
    console.log('Exporting data...');
    // Implement export logic here
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h3'>Tabular Data</Typography>
        </Box>
        <Box>
          <Typography variant='h6'>Dashboard - Tabular Data</Typography>
        </Box>
      </Box>
      <ResponsiveBanner handleButtonClick={handleButtonClick} />
      {showParameters && (
        <ParameterSection
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          exportData={exportData}
        />
      )}
      {showAlert && <AlertSection />}
      {faults && <FaultSection />}
      {graphs && <Graphical />}
    </Box>
  );
}
export default Tabularview;
// import React, { useState } from 'react';

// ResponsiveBanner component
function ResponsiveBanner({ handleButtonClick }) {

  const statuses = ['Charging', 'Normal Operation', 'Bulb Not Sending Data','Fault'];
  // const rows = Array.from({ length: 50 }, (_, index) => ({
  //   id: index + 1,
  //   DeviceID: `86827704855${index}03`,
  //   RMSMobileNo: `57550200664${index}7`,
  //   // Config: `QCT_F157${index}`,
  //   PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
  //   LuminiareSrNo: `08234855910${index}`,
  //   PanelSrNo: `IBE12012084${index}6`,
  //   BatterySrNo: `INUBUC1254002${index}`,
  //   SystemStatus: statuses[index % statuses.length],
  //   LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
  // }));
  // Function to find SystemStatus by DeviceID

  const rows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    DeviceID: `86827704855${index}03`,
    RMSMobileNo: `57550200664${index}7`,
    // Config: `QCT_F157${index}`,
    PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
    LuminiareSrNo: `08234855910${index}`,
    PanelSrNo: `IBE12012084${index}6`,
    BatterySrNo: `INUBUC1254002${index}`,
    SystemStatus: statuses[index % statuses.length],
    LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
  }));

const findSystemStatus = (deviceId) => {
  const device = rows.find(data => data.DeviceID === deviceId);
  return device ? device.SystemStatus : 'Device not found';
};
  console.log(rows)
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // const { deviceID } = useParams();
  const data = useLocation();
  const deviceId =  data.pathname.split('/').filter(Boolean).pop();
  // Example usage
const deviceIdToFind = deviceId;
console.log(deviceIdToFind) // Replace with your DeviceID
const systemStatus = findSystemStatus(deviceIdToFind);
// console.log(`System Status for Device ${deviceIdToFind}:`, systemStatus);
    const systemstatussymbol = (status)=>{
      if( status === 'Charging'){
        return < BatteryChargingFullIcon style={{ color : 'green'}} />
      }else if( status  === 'Normal Operation'){
        return < BatteryFullIcon style={{ color : 'blue'}} />
      }else if ( status === 'Bulb Not Sending Data'){
        return < PowerOffIcon style={{ color : 'orange'}} />
      }else if  ( status === 'Fault'){
        return < ErrorIcon style={{ color : 'red'}} />
      }else { 
          return "N/A"
      }
    }
  // console.log(deviceID)
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: isSmallScreen ? '200px' : 'auto',
        overflow: 'hidden',
      }}
    >
      <BannerImage  loading="lazy"  src={logo} sx={{  filter: 'contrast(75%) ',  }} alt="Banner Image"  hueRotate={1} saturate={1} />
      <OverlayBox sx={{ top: 16, left: 16 }}>
        <ResponsiveBox>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <SmallAvatar sx={{ backgroundColor: 'white' }} alt="Remy Sharp" src={bgimage} />
          </StyledBadge>
          <Box ml={{ xs: 0, sm: 2 }} mt={{ xs: 2, sm: 0 }}>
            <div>ielecssol (SAMPLE)</div>
            <div>6vj4mb04</div>
            <div>{deviceId}</div>
          </Box>
        </ResponsiveBox>
      </OverlayBox>
      <OverlayBox sx={{ top: 16, right: 16 }}>
        <ResponsiveBox2>
          <Box>
            <Typography variant="h6">System Status</Typography>
            {/* <img src="https://via.placeholder.com/100" alt="System Status" height={10} /> */}
             { systemstatussymbol(systemStatus) }
          </Box>
          <Box>
            <Typography variant="body1">N/A</Typography>
            <Typography variant="caption">LONGITUDE</Typography>
          </Box>
          <Box>
            <Typography variant="body1">N/A</Typography>
            <Typography variant="caption">LATITUDE</Typography>
          </Box>
        </ResponsiveBox2>
      </OverlayBox>

      <OverlayBox sx={{ top: 160, left: 16 }}>
        <ResponsiveBox sx={{ gap: 3 }}>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={() => handleButtonClick('parameters')}>Parameters</Button>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}  onClick={() => handleButtonClick('showAlert')}>Alerts</Button>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}   onClick={() => handleButtonClick('showFault')}>Faults</Button>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={() => handleButtonClick('graphs')} > Graphical </Button>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>Servicing</Button>
          <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>Gallery</Button>
        </ResponsiveBox>
      </OverlayBox>
      <OverlayBox sx={{ top: 160, right: 16 }}>
        <Button variant="contained">Edit Controller</Button>
      </OverlayBox>
      <OverlayBox sx={{ top: 180, right: 16 }} >
      </OverlayBox>
    </Box>
  );
}




const OverlayBoxBottom = styled(Box)(({ theme , colors}) => ({
  // position: 'absolute',
  bottom: 0,
  // left:'50px',
  top:'350px',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark'? '#141b2d' : '#e0e0e0',
  color: 'white',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));



function ParameterSection({ rowsPerPage, setRowsPerPage, searchTerm, setSearchTerm, exportData }) {

 
  let data = [];
  for (let i = 1; i <= 50; i++) {
    data.push({
      id: i,
      serialNumber: i,
      date: `2024-06-${i.toString().padStart(2, '0')}`,
      time: `12:00 PM`,
      // pvParameters: `PV Param ${i}`,
      loadOnOff: `Load ${i % 2 === 0 ? 'On' : 'Off'}`,
      brightnessLevel: `${Math.floor(Math.random() * 100)}%`,
      pvVoltage: `${(Math.random() * 20 + 10).toFixed(2)}V`,
      pvCurrent: `${(Math.random() * 5 + 2).toFixed(2)}A`,
      pvPower: `${(Math.random() * 100).toFixed(2)}W`,
      batteryVoltage: `${(Math.random() * 12).toFixed(2)}V`,
      batteryCurrent: `${(Math.random() * 2).toFixed(2)}A`,
      batteryPower: `${(Math.random() * 20).toFixed(2)}W`,
      loadVoltage: `${(Math.random() * 10).toFixed(2)}V`,
      loadCurrent: `${(Math.random() * 1).toFixed(2)}A`,
      loadPower: `${(Math.random() * 10).toFixed(2)}W`,
    });
  }

  const columns = [
    { field: 'serialNumber', headerName: 'S.No', width: 40 },
    { field: 'date', headerName: 'Date', width: 90 },
    { field: 'time', headerName: 'Time', width: 90 },
    // { field: 'pvParameters', headerName: 'PV Parameters', width: 90 },
    {
      field: 'pvVoltage',
      headerName: 'PV Voltage',
      width: 90,
      renderHeader: () => (
        <Box  
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}
         >
         {/* <Typography variant="body1">PV Parameters</Typography> */}
         <Typography variant="body1">PV Voltage</Typography> 

         {/* <Typography>PV Parameters</Typography>
          <Typography>PV Voltage</Typography> */}
        </Box>
      ),
    },
    {
      field: 'pvCurrent',
      headerName: 'PV Current',
      width: 100,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // gap={1.5}
        >
          <Typography>PV Parameters</Typography>
          <Typography  marginBottom={2} >PV Current</Typography>
        </Box>
      ),
    },
    {
      field: 'pvPower',
      headerName: 'PV Power',
      width: 90,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}>
          {/* <Typography>PV Parameters</Typography> */}
          <Typography>PV Power</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryVoltage',
      headerName: 'Battery Voltage',
      width: 100,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}>
          {/* <Typography>Battery Parameters</Typography> */}
          <Typography>Battery Voltage</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryCurrent',
      headerName: 'Battery Current',
      width: 120,
      renderHeader: () => (
        <Box  display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // gap={1.5}
        >
          <Typography>Battery Parameters</Typography>
          <Typography  marginBottom={2} >Battery Current</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryPower',
      headerName: 'Battery Power',
      width: 100,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}>
          {/* <Typography>Battery Parameters</Typography> */}
          <Typography>Battery Power</Typography>
        </Box>
      ),
    },
    {
      field: 'loadVoltage',
      headerName: 'Load Voltage',
      width: 90,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}>
          {/* <Typography>Load Parameters</Typography> */}
          <Typography>Load Voltage</Typography>
        </Box>
      ),
    },
    {
      field: 'loadCurrent',
      headerName: 'Load Current',
      width: 110,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // gap={1.5}
        >
          <Typography>Load Parameters</Typography>
          <Typography marginBottom={2} >Load Current</Typography>
        </Box>
      ),
    },
    {
      field: 'loadPower',
      headerName: 'Load Power',
      width: 90,
      renderHeader: () => (
        <Box   display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1.5}>
          {/* <Typography>Load Parameters</Typography> */}
          <Typography>Load Power</Typography>
        </Box>
      ),
    },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <OverlayBoxBottom  theme={theme}  colors = { colors} >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ height: 400, width: '100%'  , backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white', }}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 15, page: 0 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            rows={data}
            columns={columns}
            disableSelectionOnClick
            autoPageSize
          />
        </Box>
      </Box>
    </OverlayBoxBottom>
  );
}


function AlertSection(){
  let data = [];
  for (let i = 1; i <= 50; i++) {
    data.push({
      id: i,
      serialNumber: i,
      luminaireOnTime: `08:00 PM`,  // Example data
      luminaireOffTime: `06:00 AM`, // Example data
      batteryStateOfCharge: `${Math.floor(Math.random() * 100)}`, // Adjusted to remove '%' for consistency
      batteryDOD: `${Math.floor(Math.random() * 100)}`, // Adjusted to remove '%' for consistency
    });
  }

  const columns = [
    { field: 'serialNumber', headerName: 'S.No', width: 170 },
    { field: 'luminaireOnTime', headerName: 'Luminaire On Time', width: 270 },
    { field: 'luminaireOffTime', headerName: 'Luminaire Off Time', width: 270 },
    { field: 'batteryStateOfCharge', headerName: 'Battery State of Charge (%)', width: 200 },
    { field: 'batteryDOD', headerName: 'Battery DOD', width: 250 },
    
  ];

  return (
    <OverlayBoxBottom>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              columns={columns}
              rows={data}
              disableSelectionOnClick
              autoPageSize
            />
          </Box>
        </Box>
      </Box>
    </OverlayBoxBottom>
  );
}


function FaultSection(){
  let data = [];
  for (let i = 1; i <= 50; i++) {
    data.push({
      id: i,
      serialNumber: i,
      date: `2024-06-${i.toString().padStart(2, '0')}`,
      time: `12:00 PM`,
      batteryFault: `Battery Fault ${i}`,
      moduleFault: `Module Fault ${i}`,
      luminaryFault: `Luminary Fault ${i}`,
      faultRectificationDate: `2024-07-${i.toString().padStart(2, '0')}`,
    });
  }
  const columns = [
    { field: 'serialNumber', headerName: 'S.No', width: 90 },
    { field: 'date', headerName: 'Date', width: 170 },
    { field: 'time', headerName: 'Time', width: 170 },
    { field: 'batteryFault', headerName: 'Battery Fault', width: 170 },
    { field: 'moduleFault', headerName: 'Module Fault', width: 170 },
    { field: 'luminaryFault', headerName: 'Luminary Fault', width: 170 },
    { field: 'faultRectificationDate', headerName: 'Fault Rectification Date', width: 200 },
  ];
  // S.No	Date	Time	Luminaire On Time	Luminaire Off Time	Battery Sate of charge (%)	Battery DOD(%)
  return(
    <OverlayBoxBottom>
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
         initialState={{
          // ...data.initialState,
          pagination: { paginationModel: { pageSize: 15, page: 0 }, },
        }}
        pageSizeOptions={[5, 10, 25]}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          autoPageSize
        />
      </Box>
      </Box>
    </Box>
  </OverlayBoxBottom>
  )
}


function Graphical(){
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = useLocation();
  const deviceId =  data.pathname.split('/').filter(Boolean).pop();

  return(
    <OverlayBoxBottom   >
      <Box  sx={{  display: 'flex',           // Ensure Box is a flex container
        alignItems: 'center',      // Align items vertically
        justifyContent: 'center'}} >   <Header  title={`CONTROLLER ID :  ${deviceId}`} />  </Box>
     
    <Box display="flex" flexDirection="column" gap={2}>
        {/* <Line /> */}
        <BasicArea />
        <Box sx={{ width: 'calc(100% - 20px)', margin: '20px auto' }}>
      <Box
        display="flex"
        flexWrap="wrap"
        gap="10px"
        justifyContent="space-between"
      >
        <Box
          flex="1 1 calc(25% - 10px)"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minWidth="240px"
        >
          <StatBox
            title="92.21"
            subtitle="CO2 EMISSION REDUCED"
            // progress="0.75"
            // increase="+14%"
            icon={
              <AirOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          flex="1 1 calc(25% - 10px)"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minWidth="240px"
        >
          <StatBox
            title="97.07 KWH"
            subtitle="ENERGY SAVING"
            icon={
              <EnergySavingsLeafIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          flex="1 1 calc(25% - 10px)"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minWidth="240px"
        >
          <StatBox
            title="7.4"
            subtitle="100% BRIGHTNESS HRS"
            icon={
              <Brightness7Icon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          flex="1 1 calc(25% - 10px)"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minWidth="240px"
        >
          <StatBox
            title="3.8"
            subtitle="50% BRIGHTNESS HRS"
            icon={
              <Brightness6Icon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
    </Box>
  </OverlayBoxBottom>
  )
}




// id: i,
// serialNumber: i,
// date: `2024-06-${i.toString().padStart(2, '0')}`,
// time: `12:00 PM`,
// batteryFault: `Battery Fault ${i}`,
// moduleFault: `Module Fault ${i}`,
// luminaryFault: `Luminary Fault ${i}`,
// faultRectificationDate: `2024-07-${i.toString().padStart(2, '0')}`,

// id: i,
// serialNumber: i,
// luminaireOnTime: `08:00 PM`,  // Example data
// luminaireOffTime: `06:00 AM`, // Example data
// batteryStateOfCharge: `${Math.floor(Math.random() * 100)}`, // Adjusted to remove '%' for consistency
// batteryDOD: `${Math.floor(Math.random() * 100)}`,

// id: i,
// serialNumber: i,
// date: `2024-06-${i.toString().padStart(2, '0')}`,
// time: `12:00 PM`,
// // pvParameters: `PV Param ${i}`,
// loadOnOff: `Load ${i % 2 === 0 ? 'On' : 'Off'}`,
// brightnessLevel: `${Math.floor(Math.random() * 100)}%`,
// pvVoltage: `${(Math.random() * 20 + 10).toFixed(2)}V`,
// pvCurrent: `${(Math.random() * 5 + 2).toFixed(2)}A`,
// pvPower: `${(Math.random() * 100).toFixed(2)}W`,
// batteryVoltage: `${(Math.random() * 12).toFixed(2)}V`,
// batteryCurrent: `${(Math.random() * 2).toFixed(2)}A`,
// batteryPower: `${(Math.random() * 20).toFixed(2)}W`,
// loadVoltage: `${(Math.random() * 10).toFixed(2)}V`,
// loadCurrent: `${(Math.random() * 1).toFixed(2)}A`,
// loadPower: `${(Math.random() * 10).toFixed(2)}W`,

