import React, { useEffect } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {saveAs }  from 'file-saver'
import {Button} from '@mui/material';



function CustomToolbar({ rows, columns }) {
  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(rows.map((row) => {
      const newRow = {};
      columns.forEach((col) => {
        newRow[col.headerName] = row[col.field];
      });
      return newRow;
    }));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
  };

  const handleExportCSV = () => {
    const csvData = XLSX.utils.json_to_sheet(rows.map((row) => {
      const newRow = {};
      columns.forEach((col) => {
        newRow[col.headerName] = row[col.field];
      });
      return newRow;
    }));
    const csv = XLSX.utils.sheet_to_csv(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  return (
    <GridToolbarContainer 
      sx={{ 
        bgcolor: 'white', 
        justifyContent: 'center', 
        padding: 1, 
        display: 'flex', 
        gap: 2 
      }}
    >
      {/* <GridToolbarExport /> */}
      <Button 
        onClick={handleExportExcel} 
        variant="contained" 
        color="primary" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          padding: 1,
          fontSize: '16px',
          textTransform: 'none' // Prevents uppercase transformation
        }}
      >
        <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
          Export xlsx
        </Typography>
      </Button>

      <Button 
        onClick={handleExportCSV} 
        variant="contained" 
        color="primary" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          padding: 1,
          fontSize: '16px',
          textTransform: 'none' // Prevents uppercase transformation
        }}
      >
        <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
          Export CSV
        </Typography>
      </Button>
      {/* <Button 
        onClick={handleExportExcel} 
        variant="contained" 
        color="secondary" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          padding: 1,
          fontSize: '16px',
          textTransform: 'none' // Prevents uppercase transformation
        }}
      >
        <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
          Export XLSX
        </Typography>
      </Button> */}
    </GridToolbarContainer>
  );
}
// Custom cell renderer for the 'Tabular Data' column

function TabularDataCell(params) {
  const handleClick = () => {
    window.open(`${window.location.pathname}/${params.row.DeviceID}`, '_blank');
  };

  return (
    <IconButton onClick={handleClick} sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
      color: 'white', 
        padding: '10px', 
        '&:hover': {
          background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
        },
        borderRadius: '10px',
    }} >
      <TableChartIcon />
    </IconButton>
  );
}

function Ssldata() {
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [pageSize, setPageSize] = useState(10); // State to manage page size
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page

  const rowsPerPageOptions = [10, 20, 25, 50]; // Define rows per page options

  const columns = [
    { field: 'DeviceID', headerName: 'Device ID-IMEI', width: 150 },
    { field: 'RMSMobileNo', headerName: 'RMS Mobile No', width: 150 },
    { field: 'PoleSerialNo', headerName: 'Pole Serial No', width: 150 },
    { field: 'LuminiareSrNo', headerName: 'Luminiare Sr. No.', width: 150 },
    { field: 'PanelSrNo', headerName: 'Panel Sr. No.', width: 150 },
    { field: 'BatterySrNo', headerName: 'Battery Sr. No.', width: 150 },
    { field: 'LastUpdated', headerName: 'Last Updated', width: 150 },
    { field: 'tabularData', headerName: 'Tabular Data', width: 150, renderCell: TabularDataCell, sortable: false, filterable: false },
  ];

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setLoading(true); // Set loading to true when page changes
    setTimeout(() => {
      setCurrentPage(newPage);
      setLoading(false); // Set loading to false after 0.5 seconds
      // Example: Simulate API call or data update based on newPage and pageSize
      console.log(`Page changed to ${newPage}`);
    }, 500); // Simulate 0.5 seconds delay
  };

  // Function to handle rows per page change
  const handlePageSizeChange = (newPageSize) => {
    setLoading(true); // Set loading to true when page size changes
    setTimeout(() => {
      setPageSize(newPageSize);
      setCurrentPage(1); // Reset current page when changing page size
      setLoading(false); // Set loading to false after 0.5 seconds
      // Example: Implement logic to fetch data with new page size
      console.log(`Rows per page changed to ${newPageSize}`);
    }, 500); // Simulate 0.5 seconds delay
  };

  // Generate rows with dummy data
  const rows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    DeviceID: `86827704855${index}03`,
    RMSMobileNo: `57550200664${index}7`,
    PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
    LuminiareSrNo: `08234855910${index}`,
    PanelSrNo: `IBE12012084${index}6`,
    BatterySrNo: `INUBUC1254002${index}`,
    LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
  }));

  useEffect( ()=>{
    axios.get(' https://www.ielecssol.solarlighting.co.in/api/v1/test',{
      withCredentials :  true 
    }).then(
      (response) => { 
        console.log(response) 
        }   
    ).catch( (error)=>{console.log(error)} )
  } ,[])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='h3'>Solar Street Light Data</Typography>
        <Typography variant='h6'>Dashboard - Solar Street Light Data</Typography>
      </Box>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid 
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          components={{Toolbar: () => <CustomToolbar rows={rows} columns={columns} />}}
          rowsPerPageOptions={rowsPerPageOptions} // Use defined rows per page options
          pagination
          onPageChange={handlePageChange}
          onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
          loading={loading} // Pass loading state to DataGrid
          // autoHeight={true}
          virtualization={true}
          currentPage={currentPage}
          sx={{
            '& .MuiDataGrid-columnHeaders': {     
              top: 0,          
            },
          }}
        />
      </div>
    </Box>
  );
}
export default Ssldata;




// import React, { useEffect, useState } from 'react';
// import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
// import { IconButton, Box, Typography } from '@mui/material';
// import TableChartIcon from '@mui/icons-material/TableChart';
// import axios from 'axios';
// import { exportToCsv } from 'export-to-csv'; // Import a library or function for exporting to CSV if needed

// function TabularDataCell(params) {
//   const handleClick = () => {
//     window.open(`${window.location.pathname}/${params.row.DeviceID}`, '_blank');
//   };

//   return (
//     <IconButton onClick={handleClick} sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
//       color: 'white', 
//         padding: '10px', 
//         '&:hover': {
//           background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
//         },
//         borderRadius: '10px',
//     }} >
//       <TableChartIcon />
//     </IconButton>
//   );
// }



// function CustomToolbar({ onExport }) {
//   return (
//     <GridToolbarContainer sx={{ bgcolor: 'white' }}>
//       <IconButton onClick={onExport} sx={{ marginLeft: 1 }}>
//         <TableChartIcon />
//       </IconButton>
//     </GridToolbarContainer>
//   );
// }

// function generateFilename() {
//   const now = new Date();
//   const year = now.getFullYear();
//   const month = String(now.getMonth() + 1).padStart(2, '0');
//   const day = String(now.getDate()).padStart(2, '0');
//   const hours = String(now.getHours()).padStart(2, '0');
//   const minutes = String(now.getMinutes()).padStart(2, '0');
//   const seconds = String(now.getSeconds()).padStart(2, '0');
//   return `Reports_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.csv`;
// }

// function exportDataToCSV(rows) {
//   const csvData = rows.map(row => ({
//     ...row,
//     // Add any additional processing for your data if needed
//   }));
  
//   const filename = generateFilename();
  
//   // Convert data to CSV and trigger download
//   exportToCsv(csvData, filename);
// }

// function Ssldata() {
//   const [loading, setLoading] = useState(false);
//   const [pageSize, setPageSize] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);

//   const rowsPerPageOptions = [10, 20, 25, 50];

//   const columns = [
//     { field: 'DeviceID', headerName: 'Device ID-IMEI', width: 150 },
//     { field: 'RMSMobileNo', headerName: 'RMS Mobile No', width: 150 },
//     { field: 'PoleSerialNo', headerName: 'Pole Serial No', width: 150 },
//     { field: 'LuminiareSrNo', headerName: 'Luminiare Sr. No.', width: 150 },
//     { field: 'PanelSrNo', headerName: 'Panel Sr. No.', width: 150 },
//     { field: 'BatterySrNo', headerName: 'Battery Sr. No.', width: 150 },
//     { field: 'LastUpdated', headerName: 'Last Updated', width: 150 },
//     { field: 'tabularData', headerName: 'Tabular Data', width: 150, renderCell: TabularDataCell, sortable: false, filterable: false },
//   ];

//   const handlePageChange = (newPage) => {
//     setLoading(true);
//     setTimeout(() => {
//       setCurrentPage(newPage);
//       setLoading(false);
//       console.log(`Page changed to ${newPage}`);
//     }, 500);
//   };

//   const handlePageSizeChange = (newPageSize) => {
//     setLoading(true);
//     setTimeout(() => {
//       setPageSize(newPageSize);
//       setCurrentPage(1);
//       setLoading(false);
//       console.log(`Rows per page changed to ${newPageSize}`);
//     }, 500);
//   };

//   const rows = Array.from({ length: 50 }, (_, index) => ({
//     id: index + 1,
//     DeviceID: `86827704855${index}03`,
//     RMSMobileNo: `57550200664${index}7`,
//     PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
//     LuminiareSrNo: `08234855910${index}`,
//     PanelSrNo: `IBE12012084${index}6`,
//     BatterySrNo: `INUBUC1254002${index}`,
//     LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
//   }));

//   useEffect(() => {
//     axios.get('https://www.ielecssol.solarlighting.co.in/api/v1/test', {
//       withCredentials: true
//     }).then(
//       (response) => {
//         console.log(response);
//       }
//     ).catch((error) => {
//       console.log(error);
//     });
//   }, []);

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant='h3'>Solar Street Light Data</Typography>
//         <Typography variant='h6'>Dashboard - Solar Street Light Data</Typography>
//       </Box>
//       <div style={{ height: 500, width: '100%' }}>
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           pageSize={pageSize}
//           components={{ Toolbar: () => <CustomToolbar onExport={() => exportDataToCSV(rows)} /> }}
//           rowsPerPageOptions={rowsPerPageOptions}
//           pagination
//           onPageChange={handlePageChange}
//           onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
//           loading={loading}
//           sx={{
//             '& .MuiDataGrid-columnHeaders': {
//               top: 0,
//             },
//           }}
//         />
//       </div>
//     </Box>
//   );
// }

// export default Ssldata;

