import axios from "axios";
// import { useNavigate } from "react-router-dom";

export function sendOtp(email, navigate) {
  // const navigate =  useNavigate();
  console.log( "The email in the frontend" , email);
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...")
    // dispatch(setLoading(true))
    try {
      const response = await  axios.post( 'https://www.ielecssol.solarlighting.co.in/api/v1/sendotp' , { email } ,
        {
          withCredentials: true
        } );
      // apiConnector("POST", SENDOTP_API, {
      //   email,
      //   checkUserPresent: true,
      // })
      console.log("SENDOTP API RESPONSE............", response)
      console.log(response.data.success);
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      // toast.success("OTP Sent Successfully")
      navigate("/verifymail")
    } catch (error) {
      console.log("SENDOTP API ERROR............", error)
      // toast.error("Could Not Send OTP")
    }
    // dispatch(setLoading(false))
    // toast.dismiss(toastId)
  }
}


// yukkbwphnwtxaqpt - nodemailer pass for dev