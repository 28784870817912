// import { Box, Button,  Typography, useTheme } from "@mui/material";
// import { tokens } from "../../theme";
// // import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// // import EmailIcon from "@mui/icons-material/Email";
// // import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// // import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
// import Header from "../../components/Header";
// // import LineChart from "../../components/LineChart";
// // import GeographyChart from "../../components/GeographyChart";
// // import BarChart from "../../components/BarChart";
// import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
// import LightbulbIcon from '@mui/icons-material/Lightbulb';
// // import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
// import SolarPowerTwoToneIcon from '@mui/icons-material/SolarPowerTwoTone';
// import { useNavigate } from "react-router-dom";
// import ErrorIcon from '@mui/icons-material/Error';
// import PowerOffIcon from '@mui/icons-material/PowerOff';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// // import HomeIcon from '@mui/icons-material/Home';
// import GroupIcon from '@mui/icons-material/Group';
// import BusinessIcon from '@mui/icons-material/Business';
// import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
// import FlashOnIcon from '@mui/icons-material/FlashOn';
// // import EcoIcon from '@mui/icons-material/Eco';
// // import EcoOutlinedIcon from '@mui/icons-material/EcoOutlined';
// import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
// import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';


// const Dashboard = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const navigate = useNavigate();
//   return (
//     <Box m="20px">
//       {/* HEADER */}
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Header title="SOLAR STREET LIGHT DASHBOARD" subtitle="Welcome to your dashboard" />

//         <Box>
//           <Button
//             sx={{
//               backgroundColor: colors.blueAccent[700],
//               color: colors.grey[100],
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//             }}
//           >
//             <DownloadOutlinedIcon sx={{ mr: "10px" }} />
//             Download Reports
//           </Button>
//         </Box>
//       </Box>

//       {/* GRID & CHARTS */}
      
//       <Box
//         display="grid"
//         gridTemplateColumns="repeat(12, 1fr)"
//         gridAutoRows="140px"
//         gap="20px"
//       >
//         {/* ROW 1 */}
//         <Box
//          onClick = { ()=>{
//           navigate('SSLDATA')
//         } }
//         gridColumn="span 3"
//         backgroundColor={colors.primary[400]}
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//         sx={{
//           '&:hover': {
//             backgroundColor: colors.primary[600],
//             cursor: 'pointer',
//           },
//         }}
//         >
//           <StatBox
//             title="50"
//             subtitle="SOLAR STREET LIGHT"
//             progress="0.75"
//             // increase="+14%"
//             icon={
//               <LightbulbIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           onClick = { ()=>{
//             navigate('healthylights')
//           } }
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           sx={{
//             '&:hover': {
//               backgroundColor: colors.primary[600],
//               cursor: 'pointer',
//             },
//           }}
//         >
//           <StatBox
//             title="38"
//             subtitle="HEALTHY STREET LIGHTS "
//             progress="0.50"
//             // increase="+21%"
//             icon={
//               <SolarPowerTwoToneIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           onClick = { ()=>{
//             navigate('faulylights')
//           } }
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           sx={{
//             '&:hover': {
//               backgroundColor: colors.primary[600],
//               cursor: 'pointer',
//             },
//           }}
//         >
//           <StatBox
//             title="12"
//             subtitle="FAULTY STREET LIGHT "
//             progress="0.30"
//             // increase="+5%"
//             icon={
//               <ErrorIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="1,325,134"
//             subtitle="Traffic Received"
//             progress="0.80"
//             increase="+43%"
//             icon={
//               <TrafficIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>


//         {/* ROW 2 */}
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="2"
//             subtitle="TOTAL DISTRICTS"
//             progress="0.75"
//             // increase="+14%"
//             icon={
//               <LocationCityOutlinedIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="4"
//             subtitle=" TOTAL BLOCKS  "
//             progress="0.50"
//             // increase="+21%"
//             icon={
//               <BusinessIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="8"
//             subtitle=" TOTAL PANCHAYAT "
//             progress="0.30"
//             // increase="+5%"
//             icon={
//               <GroupIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="12"
//             subtitle=" TOTAL WARDS "
//             progress="0.80"
//             increase="+43%"
//             icon={
//               <LocationCityIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>


//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="12,361 KWH"
//             subtitle=" CO2 EMISSION REDUCED BY "
//             progress="0.75"
//             // increase="+14%"
//             icon={
//               <AirOutlinedIcon 
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="431,225 KW"
//             subtitle=" ENERGY SAVING  KWH "
//             progress="0.50"
//             // increase="+21%"
//             icon={
//               <FlashOnIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//            onClick = { ()=>{
//             navigate('unpluggedcontroller')
//           } }
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           sx={{
//             '&:hover': {
//               backgroundColor: colors.primary[600],
//               cursor: 'pointer',
//             },
//           }}
//           // gridColumn="span 3"
//           // backgroundColor={colors.primary[400]}
//           // display="flex"
//           // alignItems="center"
//           // justifyContent="center"
//         >
//           <StatBox
//             title="12"
//             subtitle=" UNPLUGGED CONTROLLERS"
//             progress="0.30"
//             // increase="+5%"
//             icon={
//               <PowerOffIcon
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>
//         <Box
//           gridColumn="span 3"
//           backgroundColor={colors.primary[400]}
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <StatBox
//             title="25,000"
//             subtitle=" SOLAR HIGH MAST SYSTEM "
//             progress="0.80"
//             increase="+43%"
//             icon={
//               <WbIncandescentOutlinedIcon 
//                 sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
//               />
//             }
//           />
//         </Box>


//         {/* ROW 3 */}
//         <Box
//           gridColumn="span 4"
//           gridRow="span 2"
//           backgroundColor={colors.primary[400]}
//           p="30px"
//         >
//           <Typography variant="h5" fontWeight="600">
//             Healty Street Light
//           </Typography>
//           <Typography variant="h5" fontWeight="600">
//             Faulty Street Light
//           </Typography>
//           <Box
//             display="flex"
//             flexDirection="column"
//             alignItems="center"
//             mt="25px"
//           >
//             <ProgressCircle size="125" />
//             <Typography
//               variant="h5"
//               color={colors.greenAccent[500]}
//               sx={{ mt: "15px" }}
//             >
//               Healthy : 76%      
//               {/* $48,352 revenue generated */}
//             </Typography>
//             <Typography>  Faulty : 24%</Typography>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;


// // UNPLUGGED CONTROLLERS
// // ENERGY SAVINGS KWH
// // CO2 EMISSION REDUCED KG
// // TOTAL WARDS
// // TOTAL PANCHAYATS
// // TOTAL BLOCKS
// // TOTAL BLOCKS
// // TOTAL DISTRICTS
// // TOTAL 
import React from "react";
import { Box, Button, Typography, useTheme, Snackbar } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SolarPowerTwoToneIcon from '@mui/icons-material/SolarPowerTwoTone';
import { useNavigate, useLocation } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import { usePDF } from "react-to-pdf";

const generateFilename = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `Reports_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.pdf`;
};

const Dashboard = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = React.useState(location.state?.loginSuccess || false);
  const fileName = generateFilename ();
  const [username] = React.useState(location.state?.username || '');
  const { toPDF , targetRef} = usePDF({filename : `${fileName}`})
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="SOLAR STREET LIGHT DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => toPDF()}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Box
        ref={targetRef}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
         onClick = { ()=>{
          navigate('SSLDATA')
        } }
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          '&:hover': {
            backgroundColor: colors.primary[600],
            cursor: 'pointer',
          },
        }}
        >
          <StatBox
            title="50"
            subtitle="SOLAR STREET LIGHT"
            progress="0.75"
            icon={
              <LightbulbIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          onClick = { ()=>{
            navigate('healthylights')
          } }
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            '&:hover': {
              backgroundColor: colors.primary[600],
              cursor: 'pointer',
            },
          }}
        >
          <StatBox
            title="38"
            subtitle="HEALTHY STREET LIGHTS "
            progress="0.50"
            icon={
              <SolarPowerTwoToneIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          onClick = { ()=>{
            navigate('faulylights')
          } }
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            '&:hover': {
              backgroundColor: colors.primary[600],
              cursor: 'pointer',
            },
          }}
        >
          <StatBox
            title="12"
            subtitle="FAULTY STREET LIGHT "
            progress="0.30"
            icon={
              <ErrorIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="2"
            subtitle="TOTAL DISTRICTS"
            progress="0.75"
            icon={
              <LocationCityOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="4"
            subtitle=" TOTAL BLOCKS  "
            progress="0.50"
            icon={
              <BusinessIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="8"
            subtitle=" TOTAL PANCHAYAT "
            progress="0.30"
            icon={
              <GroupIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12"
            subtitle=" TOTAL WARDS "
            progress="0.80"
            increase="+43%"
            icon={
              <LocationCityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361 KWH"
            subtitle=" CO2 EMISSION REDUCED BY "
            progress="0.75"
            icon={
              <AirOutlinedIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225 KW"
            subtitle=" ENERGY SAVING  KWH "
            progress="0.50"
            icon={
              <FlashOnIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
           onClick = { ()=>{
            navigate('unpluggedcontroller')
          } }
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            '&:hover': {
              backgroundColor: colors.primary[600],
              cursor: 'pointer',
            },
          }}
        >
          <StatBox
            title="12"
            subtitle=" UNPLUGGED CONTROLLERS"
            progress="0.30"
            icon={
              <PowerOffIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="25,000"
            subtitle=" SOLAR HIGH MAST SYSTEM "
            progress="0.80"
            increase="+43%"
            icon={
              <WbIncandescentOutlinedIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Healthy Street Light
          </Typography>
          <Typography variant="h5" fontWeight="600">
            Faulty Street Light
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              Healthy: 76%
            </Typography>
            <Typography>Faulty: 24%</Typography>
          </Box>
        </Box>
      </Box>

      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={3000}
        message={`Login Successful. Welcome, ${username}!`}
      />
    </Box>
  );
};
export default Dashboard;
