// import React from 'react'
// import { useState } from "react";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";
// // import Calendar from "./scenes/calendar/calendar";
// import { Outlet } from 'react-router-dom';
// import Footer from './components/Footer';
// import "./index.css";
// function Admindashboard() {
//     const [theme, colorMode] = useMode();
//     const [isSidebar, setIsSidebar] = useState(true);
//     localStorage.setItem('mode', theme.palette.mode );
//     return (
//         <>
//             <ColorModeContext.Provider value={colorMode}>
//                 <ThemeProvider theme={theme}>
//                     <CssBaseline />
//                     <div className="app"  >
//                         <Sidebar isSidebar={isSidebar} />
//                         <main className="content" >
//                             <Topbar setIsSidebar={setIsSidebar} />
//                             <Outlet/>
//                             <Footer />
//                         </main>
//                     </div>
//                 </ThemeProvider>
//             </ColorModeContext.Provider>
//         </>
//     )
// }
// export default Admindashboard

// // import * as React from 'react';
// // import {
// //   Avatar,
// //   Button,
// //   CssBaseline,
// //   TextField,
// //   FormControlLabel,
// //   Checkbox,
// //   Link,
// //   Grid,
// //   Box,
// //   Typography,
// //   Container,
// //   Snackbar,
// //   createTheme,
// //   ThemeProvider,
// // } from '@mui/material';
// // import WbSunnyIcon from '@mui/icons-material/WbSunny';
// // import { useNavigate } from 'react-router-dom';
// // import axios from 'axios';
// // import loginimage from './loginimage.jpg';
// // import { styled } from '@mui/system';
// // // import WbSunnyIcon from '@material-ui/icons/WbSunny';
// // function Copyright(props) {
// //   return (
// //     <Typography variant="body2" color="text.secondary" align="center" {...props}>
// //       {'Copyright © '}
// //       <Link color="inherit" href="https://solalrstreetlightyojana.com/">
// //         solalrstreetlightyojana.com
// //       </Link>{' '}
// //       {new Date().getFullYear()}
// //       {'.'}
// //     </Typography>
// //   );
// // }

// // const defaultTheme = createTheme();

// // export default function SignIn({ setAuth }) {
// //   const [open, setOpen] = React.useState(false);
// //   const [snackbarMessage, setSnackbarMessage] = React.useState('');

// //   const navigate = useNavigate();
// //   const handleSubmit = (event) => {
// //     event.preventDefault();

// //     const data = new FormData(event.currentTarget);
// //     const email = data.get('email');
// //     const password = data.get('password');

// //     if (email && password && email !== "" && password !== "") {
// //       axios.post("https://www.ielecssol.solarlighting.co.in/api/v1/login",
// //         {
// //           email: data.get('email'),
// //           password: data.get('password')
// //         })
// //         .then(
// //           (response) => {
// //             setAuth(true);
// //             localStorage.setItem("auth", "true");
// //             navigate("/Admin");
// //           }
// //         ).catch(
// //           (err) => {
// //             setOpen(true);
// //             setSnackbarMessage("Login Failed: Incorrect Credentials");
// //           }
// //         );
// //     } else if (email && !password) {
// //       setOpen(true);
// //       setSnackbarMessage("Please enter your password.");
// //     } else if (password && !email) {
// //       setOpen(true);
// //       setSnackbarMessage("Please enter your email.");
// //     } else {
// //       setOpen(true);
// //       setSnackbarMessage("Please enter both email and password.");
// //     }
// //   };

// //   const LoginImage = styled('img')({
// //     width: '100%',
// //     objectFit: 'cover',
// //   });

// //   const handleClose = () => {
// //     setOpen(false);
// //   };

// //   React.useEffect(() => {
// //     // Add the black background class to the root element
// //     document.getElementById('root').classList.add('login-background');

// //     // Remove the black background class when the component unmounts
// //     return () => {
// //       document.getElementById('root').classList.remove('login-background');
// //     };
// //   }, []);


// //   return (
// //     // <ThemeProvider theme={defaultTheme}>
// //       <Container component="main" maxWidth="md"  >
// //         <CssBaseline />
// //         <Grid container spacing={2} alignItems="center"   >
// //           <Grid item xs={12} md={6}>
// //             <Box>
// //               <LoginImage src={loginimage} alt="Sample" />
// //             </Box>
// //           </Grid>
// //           <Grid item xs={12} md={6}>
// //             <Box
// //               sx={{
// //                 marginTop: 8,
// //                 display: 'flex',
// //                 flexDirection: 'column',
// //                 alignItems: 'center',
// //               }}
// //             >
// //                  <Typography
// //         variant="h6"
// //         component="h3"
// //         sx={{
// //           marginRight: 1,
// //           fontWeight: 'bold',
// //           fontSize: '0.875rem', // text-sm equivalent
// //           color: 'text.secondary', // text-slate-500 equivalent
// //         }}
// //       >

// // <Box
// //       sx={{
// //         my: 5,
// //         display: 'flex',
// //         alignItems: 'center',
// //         '&:before, &:after': {
// //           content: '""',
// //           mt: 0.5,
// //           flex: 1,
// //           borderTop: '1px solid',
// //           borderColor: 'neutral.300',
// //         },
// //       }}
// //     >
// //       <Typography
// //         component="p"
// //         sx={{
// //           mx: 4,
// //           mb: 0,
// //           textAlign: 'center',
// //           fontWeight: 'bold',
// //           color: 'text.secondary',
// //         }}
// //       >
// //         <Box
// //           sx={{
// //             display: 'flex',
// //             justifyContent: 'center',
// //             alignItems: 'center',
// //             width: '100%',
// //           }}
// //         >
// //         <Typography
// //           component="div"
// //           sx={{
// //             color: 'yellow', // text-amber-300 equivalent
// //             fontWeight: 500,
// //           }}
// //         >
// //           Welcome Back!
// //         </Typography>
// //         <Typography>
// //         To MukhyaMantri Solar Street Light Yojana.
// //         </Typography>
// //         </Box>
// //       </Typography>
// //     </Box>

        
        
// //       </Typography>
// //               <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
// //                 <WbSunnyIcon />
// //               </Avatar>
// //               {/* <Typography component="h1" variant="h5">
// //                 Log in
// //               </Typography> */}
// //               <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
// //                 <TextField
// //                   margin="normal"
// //                   required
// //                   fullWidth
// //                   id="email"
// //                   label="Email Address"
// //                   name="email"
// //                   autoComplete="email"
// //                   autoFocus
// //                 />
// //                 <TextField
// //                   margin="normal"
// //                   required
// //                   fullWidth
// //                   name="password"
// //                   label="Password"
// //                   type="password"
// //                   id="password"
// //                   autoComplete="current-password"
// //                 />
// //                 <FormControlLabel
// //                   control={<Checkbox value="remember" color="primary" />}
// //                   label="Remember me"
// //                 />
// //                 <Button
// //                   type="submit"
// //                   fullWidth
// //                   variant="contained"
// //                   sx={{ mt: 3, mb: 2 }}
// //                 >
// //                   Log In
// //                 </Button>
// //                 <Grid container>
// //                   <Grid item xs>
// //                     <Link href="/" variant="body2">
// //                       Forgot password?
// //                     </Link>
// //                   </Grid>
// //                 </Grid>
// //               </Box>
// //             </Box>
// //           </Grid>
// //           <Grid item xs={12}>
// //             <Copyright sx={{ mt: 8, mb: 4 }} />
// //           </Grid>
// //           <Snackbar
// //             anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
// //             open={open}
// //             onClose={handleClose}
// //             autoHideDuration={2000}
// //             message={snackbarMessage}
// //             key="topcenter"
// //           />
// //         </Grid>
// //       </Container>
// //     // </ThemeProvider>
// //   );
// // }

import React from 'react'
import { useState } from "react";
// import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
// import Dashboard from "./scenes/dashboard";
// import Team from "./scenes/team";
// import Invoices from "./scenes/invoices";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
// import FAQ from "./scenes/faq";
// import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import Calendar from "./scenes/calendar/calendar";
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import "./index.css";
function Admindashboard() {
    const [theme, colorMode] = useMode();
    // console.log("The theme is ",theme.palette.mode);
    // console.log("The color mode ",colorMode)
    const [isSidebar, setIsSidebar] = useState(false);
    localStorage.setItem('mode', theme.palette.mode );
    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <Sidebar isSidebar={isSidebar} />
                        <main className="content"  >
                            <Topbar setIsSidebar={setIsSidebar} />
                            <Outlet/>
                            <Footer />
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    )
}
export default Admindashboard
// sx={{ zIndex: 800,}} on the classname context 