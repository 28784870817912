const statuses = ['Charging', 'Normal Operation', 'Bulb Not Sending Data','Fault'];

const rows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    DeviceID: `86827704855${index}03`,
    RMSMobileNo: `57550200664${index}7`,
    // Config: `QCT_F157${index}`,
    PoleSerialNo: `KHA/BEL/BOB/W03/${index}`,
    LuminiareSrNo: `08234855910${index}`,
    PanelSrNo: `IBE12012084${index}6`,
    BatterySrNo: `INUBUC1254002${index}`,
    SystemStatus: statuses[index % statuses.length],
    LastUpdated: `2023-06-${String(index + 1).padStart(2, '0')}`,
  }));
export const ssldata = rows;

