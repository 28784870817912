// // import { useState } from "react";
// import { Routes, Route,  } from "react-router-dom";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
// import Team from "./scenes/team";
// import Invoices from "./scenes/invoices";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
// import FAQ from "./scenes/faq";
// import Geography from "./scenes/geography";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";
// import Calendar from "./scenes/calendar/calendar";
// import { useMode } from "./theme";


// import Login from "./Login";
// import Admindashboard from "./Admindashboard";
// import Pagenotfound from "./components/Pagenotfound";
// import SsLdata from "./scenes/SSL Data/SsLdata";
// import Tabularview from "./scenes/SSL Data/Tabularview";
// import Faultylight from "./scenes/SSL Data/Faultylight";
// import { useNavigate } from "react-router-dom";
// import React from "react";
// import { useEffect } from "react";
// import Unpluggedlight from "./scenes/SSL Data/Unpluggedlight";
// import Healthylight from './scenes/SSL Data/Healthylight'
// import Faultrectificationlog  from './scenes/SSL Data/Faultrectificationlog';
// import Singlefaultrectificationlog from './scenes/SSL Data/Singlefaultrectificationlog'

// // import Table from "./scenes/table";
// function App() {
//   // const [theme, colorMode] = useMode();
//   // const [isSidebar, setIsSidebar] = useState(true);
//   const [auth,setAuth] = React.useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const isAuth = localStorage.getItem("auth") === "true";
//     setAuth(isAuth);
//     if (!isAuth) {
//       navigate("/");
//     }
//   }, [navigate]);
//   console.log(" Hello World ")
//   return (
//     <Routes>
//       <Route path="/"  element={<Login   setAuth={setAuth} />} />
//       {auth ? (        
//         <Route path="/Admin" element={<Admindashboard />}>
//           <Route index element={<Dashboard />} />
//           <Route path="SSLDATA" element={<SsLdata />} />
//           <Route path="SSLDATA/:DeviceID" element={<Tabularview />} />
//           <Route path="faulylights" element={<Faultylight />} />
//           <Route path="unpluggedcontroller" element={<Unpluggedlight />} />
//           <Route path="healthylights" element= { <Healthylight />} />
//           <Route path="faultrectificationlog" element={ <Faultrectificationlog /> } />
//           <Route path="singlefaultrectificationlog" element={ <Singlefaultrectificationlog /> } />
//         </Route>
//       ) : (
//         navigate("/")
//       )}
//       <Route path="*" element={<Pagenotfound />} />
//       {/* <Route path="/tabulardata" element={ <Table /> } /> */}
//       {/* <Route path="*" element={<Pagenotfound />}/> */}

//     </Routes>  
//   );
// }
// export default App;


import Login from "./Login";
import Admindashboard from "./Admindashboard";
import Pagenotfound from "./components/Pagenotfound";
import SsLdata from "./scenes/SSL Data/SsLdata";
import Tabularview from "./scenes/SSL Data/Tabularview";
import Faultylight from "./scenes/SSL Data/Faultylight";
import { useNavigate, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Unpluggedlight from "./scenes/SSL Data/Unpluggedlight";
import Healthylight from './scenes/SSL Data/Healthylight';
import Faultrectificationlog from './scenes/SSL Data/Faultrectificationlog';
import Singlefaultrectificationlog from './scenes/SSL Data/Singlefaultrectificationlog';
// import SignUp from "./Signup";
import SignUp from "./Signup";
import VerifyEmail from './VerifyEmail'
import token from './slices/authSlice'

function App() {
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const tokenvalue = token;
  console.log(tokenvalue)

  useEffect(() => {
    const isAuth = localStorage.getItem("auth") === "true";
    setAuth(isAuth);
    if (!isAuth) {
      navigate("/"); // Navigate outside of rendering logic
    }
  }, [navigate]);
  console.log(" HELLO WORLD ")

  return (

    <Routes>
      <Route path="/" element={<Login setAuth={setAuth} />} />

      {auth && (
        <>
            <Route path="/signin" element = { <SignUp/> } />
            <Route path="/verifymail" element={ <VerifyEmail /> } />
        <Route path="/Admin" element={<Admindashboard />}>
          <Route index element={<Dashboard />} />
          <Route path="SSLDATA" element={<SsLdata />} />
          <Route path="SSLDATA/:DeviceID" element={<Tabularview />} />
          <Route path="faulylights" element={<Faultylight />} />
          <Route path="unpluggedcontroller" element={<Unpluggedlight />} />
          <Route path="healthylights" element={<Healthylight />} />
          <Route path="faultrectificationlog" element={<Faultrectificationlog />} />
          <Route path="singlefaultrectificationlog" element={<Singlefaultrectificationlog />} />
        </Route>
         </>
      )}
      <Route path="*" element={<Pagenotfound />} />
    </Routes>
  );
}
export default App;

