import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import rootReducer from "./reducer";
import {configureStore} from "@reduxjs/toolkit"


const store = configureStore({
  // store: store
  reducer : rootReducer
})
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store} >
      {/* <React.StrictMode> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  {/* </React.StrictMode> */}
  </Provider>
  
);
