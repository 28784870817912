
import React from 'react'
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
// import { useMode } from "../theme";

function Copyright(props) {
    // const [theme, colorMode] = useMode();
    // console.log(theme.palette.mode);
    // console.log(colorMode)  const textColor = mode === 'dark' ? 'white' : 'black';
    console.log(localStorage.mode);
    let mode = localStorage.mode ; 
    const textColor = mode === 'dark' ? 'white' : 'black';
    return (
      <Typography  
      style={{ color: textColor }}
       variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://solarstreetlightyojana.com/">
          solalrstreetlightyojana.com 
        </Link>{' '}  
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

function Footer() {
  return (
    <div>
      <Copyright />
    </div>
  )
}
// So the ielecssol will point to the aws machine now generate the ssl and host app and acess the api 
// now use the subdomain to ngnix and how to verify that 
export default Footer

