import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {  Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useState } from 'react';


const columns = [
    { field: 'id', headerName: 'S.No', width: 90 },
    { field: 'deviceId', headerName: 'Device ID-IMEI', width: 150 },
    { field: 'poleSerialNo', headerName: 'Pole Serial No.', width: 150 },
    { field: 'luminaireSerialNo', headerName: 'Luminaire Sr. No.', width: 150 },
    { field: 'panelSerialNo', headerName: 'Panel Sr. No.', width: 150 },
    { field: 'batterySerialNo', headerName: 'Battery Sr. No.', width: 150 },
    { field: 'installationDate', headerName: 'Installation Date', width: 150 },
    { field: 'lightHealthyDays', headerName: 'Light Healthy Working Days', width: 180 },
    { field: 'lightFaultyDays', headerName: 'Light Faulty Days', width: 150 },
  ];
  const randomDate = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  };
  const generateRandomData = (numRows) => {
    return Array.from({ length: numRows }, (_, index) => ({
      id: index + 1,
      deviceId: `86827704855${index}03`,
      poleSerialNo: `PSN${Math.floor(Math.random() * 100000)}`,
      luminaireSerialNo: `LSN${Math.floor(Math.random() * 100000)}`,
      panelSerialNo: `PSN${Math.floor(Math.random() * 100000)}`,
      batterySerialNo: `BSN${Math.floor(Math.random() * 100000)}`,
      installationDate: randomDate(new Date(2020, 0, 1), new Date()).toLocaleDateString(),
      lightHealthyDays: Math.floor(Math.random() * 365),
      lightFaultyDays: Math.floor(Math.random() * 100),
    }));
  };
  
  const rows = generateRandomData(50);

function Singlefaultrectificationlog() {

  const [loading, setLoading] = useState(false); // State to manage loading state
  // const [pageSize, setPageSize] = useState(10); // State to manage page size
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page

  const rowsPerPageOptions = [10, 20, 25, 50]; // Define rows per page options
  // Function to handle page change

  const [pageSize, setPageSize] = useState(10); // 

  const handlePageChange = (newPage) => {
    setLoading(true); // Set loading to true when page changes
    setTimeout(() => {
      setCurrentPage(newPage);
      setLoading(false); // Set loading to false after 0.5 seconds
      // Example: Simulate API call or data update based on newPage and pageSize
      console.log(`Page changed to ${newPage}`);
    }, 500); // Simulate 0.5 seconds delay
  };

  // Function to handle rows per page change
  const handlePageSizeChange = (newPageSize) => {
    setLoading(true); // Set loading to true when page size changes
    setTimeout(() => {
      setPageSize(newPageSize);
      setCurrentPage(1); // Reset current page when changing page size
      setLoading(false); // Set loading to false after 0.5 seconds
      // Example: Implement logic to fetch data with new page size
      console.log(`Rows per page changed to ${newPageSize}`);
    }, 500); // Simulate 0.5 seconds delay
  };

  

  return (
<Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box><Typography variant='h3'>Solar Street Light Data</Typography></Box>
        <Box>
          <Typography variant='h6'>Dashboard - Light Working Days</Typography>
        </Box>
      </Box>
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid 
      rows={rows} 
      columns={columns} 
      // pageSize={10} 
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      pagination
      onPageChange={handlePageChange}
      onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
      loading={loading} // Pass loading state to DataGrid
      // autoHeight={true}
      virtualization={true}
      currentPage={currentPage}
      sx={{
        '& .MuiDataGrid-columnHeaders': {     
          top: 0,          
        },
      }} 
      />
    </div>
  </Box>  )
}
export default Singlefaultrectificationlog
