import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import {  Button, Typography, Container, Paper } from "@mui/material";
import signUp from './service/Operations/authapi';

function VerifyEmail() {
  const [otp, setOtp] = useState("");
  const { signupData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to signup if signupData is not available
    if (!signupData) {
      navigate("/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupData, navigate]);

  const handleVerifyAndSignup = (e) => {
    e.preventDefault();
    if (otp.trim() === "" || otp.length < 6) {
      // Add a simple alert or error message here
      alert("Please enter a valid OTP.");
      return;
    }

    const { firstName, lastName, email, password } = signupData;

    dispatch(signUp(firstName, lastName, email, password, otp, navigate));
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Verify Your Email
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
          A verification email has been sent to your email address. Please enter the code below.
        </Typography>
        <form onSubmit={handleVerifyAndSignup} style={{ width: '100%' }}>
          <MuiOtpInput 
            value={otp} 
            onChange={handleChange} 
            length={6} // Set the number of input boxes
            sx={{ marginBottom: 2, width: '100%' }} // Ensure it fits well within the form
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Verify Email
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default VerifyEmail;
